<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Typesense Collections Summary</v-card-title>
          <v-card-text>
            <v-row>
              <v-col> Collections: {{ collections.length }} </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="updateCollectionsList" color="green">Refresh</v-btn>
            <v-btn @click="reIndex" color="red">
              Reindex primary collection (current site)</v-btn
            >
            <v-btn @click="initCollections" color="red">Init Collections</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        v-for="collection in collectionsList"
        :key="collection.name"
      >
        <v-card>
          <v-card-title>Collection: {{ collection.name }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col> Documents: {{ collection.num_documents }} </v-col>
              <v-col> Fields: {{ collection.fields.length }} </v-col>
            </v-row>
            <v-row v-if="collection.indexStatus">
              <v-col>
                <strong>Current Index Status</strong>
                <div>
                  {{ collection.indexStatus.status }}:
                  {{ collection.indexStatus.progress }}/{{
                    collection.indexStatus.total
                  }}
                  <!-- Calculate the progress -->
                  ({{
                    Math.round(
                      (collection.indexStatus.progress /
                        collection.indexStatus.total) *
                        100
                    )
                  }}%)
                </div>
                <div>
                  <v-btn @click="stopIndex" color="red">Stop Indexing</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="updateCollectionsList">Refresh</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed } from "vue-demi";
import { api } from "../utils/feathers-api";

export default {
  name: "AdminTypesense",
  setup() {
    const collections = ref([]);
    const collectionIndexStatus = ref([]);
    const typesenseService = api.service("typesense");

    const updateCollectionsList = async () => {
      collections.value = [];
      const response = await typesenseService.find({
        query: { type: "collections" },
      });
      collections.value = response;
      updateIndexStatus();
    };

    const updateIndexStatus = async () => {
      const response = await typesenseService.find();
      console.log("updateIndexStatus response", response);
      collectionIndexStatus.value = response;
    };

    const reIndex = async () => {
      // Update the index status
      await updateIndexStatus();
      // Check if any indexes are currently running
      const runningIndexes = !!collectionIndexStatus.value.length;
      if (runningIndexes) {
        alert(
          "There are currently indexes running. Please wait until they are complete before reindexing."
        );
        return;
      }
      const response = await typesenseService.create({
        type: "flow",
      });
      console.log("reIndex response", response);
      setTimeout(() => {
        updateIndexStatus();
      }, 1000);
    };

    const initCollections = async () => {
      const response = await typesenseService.update(null, {
        type: "collections",
      });
      console.log("initCollections response", response);
      setTimeout(() => {
        updateCollectionsList();
      }, 5000);
    };

    const stopIndex = async () => {
      const response = await typesenseService.remove("tracker");
      console.log("stopIndex response", response);
      setTimeout(() => {
        updateIndexStatus();
      }, 1000);
    };

    updateCollectionsList();

    const collectionsList = computed(() => {
      return collections.value.map((collection) => {
        return {
          ...collection,
          indexStatus: collectionIndexStatus.value.find(
            (status) => status.collection === collection.name
          ),
        };
      });
    });

    return {
      collections,
      collectionsList,
      updateCollectionsList,
      updateIndexStatus,
      reIndex,
      stopIndex,
      initCollections,
    };
  },
};
</script>
